import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import List from '../../components/List'

const ourpartners = ({ location }) => {
  const queryData = useStaticQuery(graphql`
    {
      allContentfulPartners(sort: { fields: name, order: ASC }) {
        edges {
          node {
            id
            name
            slug
            callToActionLink
            logo {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const TYPE = 'Partners'
  const IMAGE_HEIGHT = null
  const data = queryData.allContentfulPartners.edges.map(obj => ({
    id: obj.node.id,
    title: obj.node.name,
    body: null,
    image: obj.node.logo,
    imageHeight: IMAGE_HEIGHT,
    slug: obj.node.slug,
    category: obj.node.category,
    type: TYPE
  }))

  return (
    <Layout>
      <SEO title="Our Partners" />
      <div className="container">
        <List data={data} itemType="subMenu" className="items-container" />
      </div>
    </Layout>
  )
}

export default ourpartners

ourpartners.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
